import { object, string, array, boolean, number } from 'yup';
import {
  type RedundancyType,
  redundancyTypeOptions,
} from '@shiftsmartinc/shiftsmart-types';

const RedundancySettingsSchema = object({
  autoCreateFloaterSettings: object({
    floaterDispatchPref: string(),
    floaterShiftTitle: string(),
    floatersStartTimeBufferMinutes: number(),
    linearTransformConfig: object({
      individualFFRBuffer: number().optional(),
      individualFFRFloor: number()
        .min(0, 'Must be a decimal between 0 and 1')
        .max(1, 'Must be a decimal between 0 and 1')
        .optional(),
      numFloatersAdditiveBuffer: number().optional(),
      numFloatersMultiplier: number().optional(),
    }).optional(),
  }),
  autoCreateRedundantShifts: object({
    disableCheckinCheckoutCodeUpdates: boolean(),
    increaseFillRateDispatchPref: string(),
    increaseFillRateRedundancyType: string<RedundancyType>().oneOf(
      redundancyTypeOptions,
    ),
    isEnabled: boolean(),
    maximumFFRForDupeCreationDecimalPercentage: number()
      .min(0, 'Must be a decimal between 0 and 1')
      .max(1, 'Must be a decimal between 0 and 1'),
    maximumHighRiskRedundancyDupePercentage: number()
      .min(0, 'Must be a decimal between 0 and 1')
      .max(1, 'Must be a decimal between 0 and 1'),
    minimumFillRateForDupeCreationDecimalPercentage: number()
      .min(0, 'Must be a decimal between 0 and 1')
      .max(1, 'Must be a decimal between 0 and 1'),
    overrideRules: array(
      object({
        maxReliabilityScore: number()
          .min(0, 'Must be a decimal between 0 and 1')
          .max(1, 'Must be a decimal between 0 and 1')
          .optional(),
        maxShiftsWorked: number()
          .min(0, 'Must be greater or equal to 0')
          .optional(),
        minReliabilityScore: number()
          .min(0, 'Must be a decimal between 0 and 1')
          .max(1, 'Must be a decimal between 0 and 1')
          .optional(),
        minShiftsWorked: number()
          .min(0, 'Must be greater or equal to 0')
          .optional(),
      }),
    ),
  }),
  backupSettings: object({
    backupDispatchPrefId: string(),
    offsetShiftStartMinutes: number(),
  }),
  shadowSettings: object({
    shadowDispatchPrefId: string(),
  }),
});

const BonusSettingsSchema = object({
  dynamicBonus: object({
    bonusPrefTemplateId: string(),
    enableBonusOnCoveredShifts: boolean(),
    enabled: boolean(),
    guardrail: object({
      maxAbsValueForWindow: number(),
      maxAbsValuePerItemMajor: number(),
      maxDecimalPercentageForWindow: number(),
      timeHorizonMinutes: number(),
    }),
    pushNotificationEnabled: boolean(),
  }),
  manualBonus: object({
    allowFromShiftStartTimeInMinutes: number(),
    disabled: boolean(),
  }),
});

const ShiftSettingsSchema = object({
  dispatchScoring: object({
    batchSize: number(),
    enabled: boolean(),
    limit: number(),
    partnerPools: array(string().defined().required()).optional(),
  }).optional(),
  durationAutoCheckout: number().optional(),
  extraMinutesAutoCheckout: number().optional(),
  rescheduleShift: object({
    checkFromShiftStartMinutes: number().optional(),
    delayByMinutes: number().optional(),
    delayMaxMinutes: number().optional(),
    maxHourOfDay: number().optional(),
    minHourOfDay: number().optional(),
  }).optional(),
}).optional();

const AutoCallSettingsSchema = object({
  enableStandardConfirmationCalls: boolean(),
  enableTrainingConfirmationCalls: boolean(),
});

const BaseSettingsSchema = object({
  autoCall: AutoCallSettingsSchema,
  bonus: BonusSettingsSchema,
  redundancySettings: RedundancySettingsSchema,
  shifts: ShiftSettingsSchema.optional(),
});

const MSAConfigSchema = BaseSettingsSchema.concat(
  object({ msaId: string().required() }),
);

const ProjectSettingsSchema = BaseSettingsSchema.concat(
  object({ msaConfig: array(MSAConfigSchema) }),
);

/**
 * This is the base project schema used for both UpdateProject and CreateProject forms.
 */
export const BaseProjectSchema = object({
  backgroundCheckPackage: string(),
  currentEmployeeCheck: boolean(),
  drugTest: string(),
  drugTestRequired: boolean(),
  enableNestingShiftFlow: boolean(),
  settings: ProjectSettingsSchema,
  shiftPickUpBeforeCalibration: boolean(),
  showLegacyCompanyOnboarding: boolean(),
  trainingIds: array(string().defined().required()),
  trainingRequirements: object({
    isEnabled: boolean(),
    onsiteTrainingShift: object({
      isEnabled: boolean(),
      requireBackgroundCheckAtTraining: boolean(),
    }),
    postTrainingVideo: object({
      isEnabled: boolean(),
      postTrainingVideoInterviewIds: array(string().defined().required()),
    }),
    virtualTrainingShift: object({
      isEnabled: boolean(),
      payoutByStore: array(
        object({
          amount: number(),
          storeId: string(),
        })
          .defined()
          .required(),
      ),
      virtualTrainingShiftIds: array(string().defined().required()),
    }),
  }),
});
